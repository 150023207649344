import React, { useCallback, memo, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import FormError from "./common/FormError";

const MATERIAL_OPTIONS = [
  {
    value: "YA_TENGO_EL_MATERIAL",
    label: "Ya tengo el material",
    icon: "🎯",
    description: "Ya cuento con el material publicitario listo para usar",
  },
  {
    value: "NO_TENGO_EL_MATERIAL",
    label: "No tengo el material",
    icon: "🤝",
    description: "El medio/programa deberá crear la pieza publicitaria",
  },
  {
    value: "OTRA_SITUACION",
    label: "Otra situación",
    icon: "💡",
    description: "Tengo una situación diferente que me gustaría especificar",
  },
];

const MATERIAL_TYPES = [
  { value: "video", label: "Video", icon: "🎥" },
  { value: "audio", label: "Audio", icon: "🎙️" },
  { value: "imagen", label: "Imagen", icon: "🖼️" },
  { value: "texto", label: "Texto", icon: "📝" },
];

const DURATION_OPTIONS = [
  { value: "15", label: "15 segundos" },
  { value: "30", label: "30 segundos" },
  { value: "45", label: "45 segundos" },
  { value: "60", label: "1 minuto" },
  { value: "custom", label: "Otra Duración" }
];

const DATE_OPTIONS = [
  {
    value: "postulacion",
    label: "Fecha Límite de Postulación (obligatoria)",
    icon: "⏳",
    description:
      "¿Hasta cuándo se pueden postular los medios/programas?",
  },
  {
    value: "inicio",
    label: "Fecha de Inicio (opcional)",
    icon: "📆",
    description:
      "¿Cuándo te gustaría que comience tu campaña?",
  },
  {
    value: "fin",
    label: "Fecha de Finalización (opcional)",
    icon: "📋",
    description:
      "¿Cuándo te gustaría que termine tu campaña?",
  },
];

const DateInput = memo(({ value, onChange, className, dateType }) => {
  const inputRef = useRef(null);
  const today = new Date().toISOString().split('T')[0];
  const minDate = dateType === 'postulacion' 
    ? new Date(Date.now() + 5 * 24 * 60 * 60 * 1000).toISOString().split('T')[0] 
    : today;

  const handleContainerClick = () => {
    if (inputRef.current) {
      try {
        inputRef.current.focus();
        if (window.matchMedia('(min-width: 768px)').matches) {
          inputRef.current.showPicker().catch(() => {});
        }
      } catch (error) {
        console.debug('DatePicker fallback activated');
      }
    }
  };

  return (
    <div 
      className="date-input-container" 
      onClick={handleContainerClick}
    >
      <Form.Control
        ref={inputRef}
        type="date"
        value={value || ""}
        onChange={onChange}
        className={`form-control-custom ${className}`}
        min={minDate}
      />
    </div>
  );
});

const Step5 = memo(({ formData, onChange, errors = {} }) => {
  const requestStorageAccess = useCallback(async () => {
    if (document.hasStorageAccess && !(await document.hasStorageAccess())) {
      try {
        await document.requestStorageAccess();
      } catch (error) {
        console.error("Error al solicitar acceso al almacenamiento:", error);
      }
    }
  }, []);

  const handleMaterialChange = useCallback(async (value) => {
    await requestStorageAccess();
    if (value !== "OTRA_SITUACION") {
      onChange("piezaPublicitariaOtro", "");
    }
    if (value !== "YA_TENGO_EL_MATERIAL") {
      onChange("tipoMaterial", "");
      onChange("duracionMaterial", "");
    }
    onChange("tienePiezaPublicitaria", value);
  }, [onChange, requestStorageAccess]);

  const handleCustomMaterialChange = useCallback((e) => {
    e.stopPropagation();
    const value = e.target.value;
    onChange("piezaPublicitariaOtro", value);
  }, [onChange]);

  const handleDurationChange = useCallback((value) => {
    onChange("duracionMaterial", value === "custom" ? "" : value);
  }, [onChange]);

  const handleCustomDurationChange = useCallback((e) => {
    const value = e.target.value;
    if (value === "" || (/^\d+$/.test(value) && parseInt(value) > 0)) {
      onChange("duracionMaterial", value);
    }
  }, [onChange]);

  const handleDateChange = useCallback(async (field, value) => {
    await requestStorageAccess();
    const formattedValue = value ? new Date(value).toISOString().split('T')[0] : '';
    onChange(field, formattedValue);
  }, [onChange, requestStorageAccess]);

  return (
    <div className="step-container">
      <div className="form-section">
        <h2 className="section-title text-center mb-3">Descripción del Producto</h2>
        <div className="description-section mb-4">
          <FormError error={errors.descripcionProducto} />
          <Form.Control
            as="textarea"
            rows={4}
            value={formData.descripcionProducto || ""}
            onChange={(e) => onChange("descripcionProducto", e.target.value)}
            placeholder="Describe tu producto o servicio con más detalle"
            className={`custom-textarea ${errors.descripcionProducto ? 'is-invalid' : ''}`}
          />
        </div>
      </div>

      <div className="form-section mb-4">
        <h2 className="section-title text-center mb-3">
          Material Publicitario
        </h2>
        <div className="section-subtitle text-center mb-4">
          ¿Ya cuentas con el material publicitario?
        </div>
        <FormError error={errors.tienePiezaPublicitaria} />

        <div className="audience-options mx-auto" style={{ maxWidth: "800px" }}>
          {MATERIAL_OPTIONS.map((option) => (
            <React.Fragment key={option.value}>
              <div
                className={`audience-option ${
                  formData.tienePiezaPublicitaria === option.value ? "active" : ""
                } ${errors.tienePiezaPublicitaria ? 'is-invalid' : ''}`}
                onClick={() => handleMaterialChange(option.value)}
              >
                <Form.Check
                  type="checkbox"
                  id={`material-${option.value}`}
                  checked={formData.tienePiezaPublicitaria === option.value}
                  onChange={() => {}}
                  onClick={(e) => e.stopPropagation()}
                  label={
                    <div className="option-content">
                      <div className="option-header">
                        {option.icon} {option.label}
                      </div>
                      <div className="option-description">
                        {option.description}
                      </div>
                    </div>
                  }
                />
                {option.value === "OTRA_SITUACION" &&
                  formData.tienePiezaPublicitaria === "OTRA_SITUACION" && (
                    <div
                      className="custom-input-wrapper"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FormError error={errors.piezaPublicitariaOtro} />
                      <Form.Control
                        type="text"
                        value={formData.piezaPublicitariaOtro || ""}
                        onChange={handleCustomMaterialChange}
                        placeholder="Especifica tu situación..."
                        className={`custom-input ${errors.piezaPublicitariaOtro ? 'is-invalid' : ''}`}
                      />
                    </div>
                  )}
              </div>
              {option.value === "YA_TENGO_EL_MATERIAL" && formData.tienePiezaPublicitaria === "YA_TENGO_EL_MATERIAL" && (
                <>
                  <div className="material-type-section mb-4">
                    <div className="section-subtitle text-center mb-3 mt-2">
                      Tipo de Material
                    </div>
                    <FormError error={errors.tipoMaterial} />
                    <div className="material-type-options">
                      {MATERIAL_TYPES.map((type) => (
                        <div
                          key={type.value}
                          className={`material-type-option ${
                            formData.tipoMaterial === type.value ? "active" : ""
                          } ${errors.tipoMaterial ? 'is-invalid' : ''}`}
                          onClick={() => {
                            onChange("tipoMaterial", type.value);
                            if (!["video", "audio"].includes(type.value)) {
                              onChange("duracionMaterial", "");
                            }
                          }}
                        >
                          {type.icon} {type.label}
                        </div>
                      ))}
                    </div>
                  </div>

                  {(formData.tipoMaterial === "video" || formData.tipoMaterial === "audio") && (
                    <div className="duration-section mb-4">
                      <div className="section-subtitle text-center mb-3">
                        Duración del {formData.tipoMaterial === "video" ? "Video" : "Audio"}
                      </div>
                      <FormError error={errors.duracionMaterial} />
                      <div className="duration-options" style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "center",
                        alignItems: "center"
                      }}>
                        {DURATION_OPTIONS.map((option) => (
                          <div
                            key={option.value}
                            className={`duration-option ${
                              (option.value === "custom" && formData.duracionMaterial !== "15" && 
                               formData.duracionMaterial !== "30" && formData.duracionMaterial !== "45" && 
                               formData.duracionMaterial !== "60") || formData.duracionMaterial === option.value ? "active" : ""
                            } ${errors.duracionMaterial ? 'is-invalid' : ''}`}
                            onClick={() => handleDurationChange(option.value)}
                            style={{
                              flex: option.value === "custom" ? "0 0 auto" : "0 0 auto",
                              minWidth: option.value === "custom" ? "auto" : "120px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "8px 16px",
                              margin: "0"
                            }}
                          >
                            {option.value === "custom" ? (
                              <div className="custom-duration-container" style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                                whiteSpace: "nowrap"
                              }}>
                                <span>Otra Duración:</span>
                                <Form.Control
                                  type="number"
                                  min="1"
                                  placeholder="segundos"
                                  value={formData.duracionMaterial || ""}
                                  onChange={handleCustomDurationChange}
                                  onClick={(e) => e.stopPropagation()}
                                  className="duration-input"
                                  style={{
                                    width: "80px",
                                    padding: "4px 8px",
                                    height: "30px",
                                    margin: "0"
                                  }}
                                />
                              </div>
                            ) : (
                              option.label
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="form-section">
        <h2 className="section-title text-center mb-3">Fechas</h2>
        <div className="section-subtitle text-center mb-4">
          Selecciona las fechas importantes para tu campaña
        </div>

        <div className="media-options mx-auto" style={{ maxWidth: "800px" }}>
          {DATE_OPTIONS.map((dateOption) => (
            <div key={dateOption.value} className="audience-option">
              <div className="option-content">
                <div className="option-header">
                  {dateOption.icon} {dateOption.label}
                </div>
                <div className="option-description">
                  {dateOption.description}
                </div>
                <FormError error={dateOption.value === "postulacion" 
                  ? errors.fechaLimitePostulacion 
                  : errors[`fecha${dateOption.value.charAt(0).toUpperCase() + dateOption.value.slice(1)}Anuncio`]} 
                />
                <DateInput
                  value={dateOption.value === "postulacion"
                    ? formData.fechaLimitePostulacion
                    : formData[`fecha${dateOption.value.charAt(0).toUpperCase() + dateOption.value.slice(1)}Anuncio`]}
                  onChange={(e) => handleDateChange(
                    dateOption.value === "postulacion" 
                      ? "fechaLimitePostulacion" 
                      : `fecha${dateOption.value.charAt(0).toUpperCase() + dateOption.value.slice(1)}Anuncio`,
                    e.target.value
                  )}
                  className={dateOption.value === "postulacion"
                    ? errors.fechaLimitePostulacion ? 'is-invalid' : ''
                    : errors[`fecha${dateOption.value.charAt(0).toUpperCase() + dateOption.value.slice(1)}Anuncio`] ? 'is-invalid' : ''}
                  dateType={dateOption.value}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Step5;
