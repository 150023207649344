export function formatDate(dateString) {
  if (!dateString) return 'No especificada';
  
  // Create date object and set to noon UTC to avoid timezone issues
  const date = new Date(dateString);
  date.setUTCHours(12, 0, 0, 0);
  
  return date.toLocaleDateString('es-ES', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

export function formatMonto(monto, simbolo = '$') {
  if (!monto) return `${simbolo}0`;
  return new Intl.NumberFormat('es-UY', {
    style: 'currency',
    currency: 'UYU',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(monto);
}
