import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchUserAds } from '../redux/userAdsSlice';
import { formatDate, formatMonto } from '../utils/formats';
import ActivitySummary from '../components/ActivitySummary';

const PageContainer = styled.div`
  min-height: 100vh;
  background: #f5f7fa;
  padding-top: 60px;
`;

const Header = styled.div`
  background: white;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1500;
`;

const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  color: #0b3357;
  margin: 0;
`;

const CreateButton = styled.button`
  background-color: #0b3357;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  &:hover {
    background-color: #1557a0;
    transform: translateY(-1px);
  }

  &::before {
    content: '+';
    font-size: 1.2rem;
  }

  @media (min-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    gap: 0.375rem;
    top: 1rem;
    right: 1rem;

    &::before {
      font-size: 1rem;
    }
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: 140px auto 2rem;
  padding: 0 1rem;
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  
  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    gap: 1.5rem;
  }
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  
  @media (min-width: 1024px) {
    gap: 1.5rem;
  }
`;

const FilterSection = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 12px rgba(11, 51, 87, 0.08);
  width: 100%;

  h2 {
    font-size: 1.25rem;
    color: #0b3357;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.8;
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #0b3357;
      transform: ${props => props.isCollapsed ? 'rotate(-90deg)' : 'rotate(0)'};
      transition: transform 0.3s ease;
    }
  }

  @media (min-width: 768px) {
    padding: 1.5rem;
  }
`;

const FilterContent = styled.div`
  overflow: hidden;
  max-height: ${props => props.isCollapsed ? '0' : '500px'};
  opacity: ${props => props.isCollapsed ? '0' : '1'};
  transition: max-height 0.3s ease, opacity 0.3s ease;
  margin-top: ${props => props.isCollapsed ? '0' : '1rem'};
`;

const FilterGroup = styled.div`
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    display: block;
    font-weight: 500;
    color: #0b3357;
    margin-bottom: 0.5rem;
  }

  select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e1e4e8;
    border-radius: 8px;
    font-size: 0.9rem;
    color: #2c3e50;
    background: white;
    cursor: pointer;
    
    &:focus {
      outline: none;
      border-color: #0b3357;
    }
  }
`;

const NewBadge = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  gap: 0.25rem;
  position: absolute;
  top: 0.75rem;
  right: ${props => props.hasStatus ? '6.5rem' : '0.75rem'};
  background: rgba(255, 87, 34, 0.15);
  color: #FF5722;
  z-index: 1;
  
  &::before {
    content: '🔥';
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    gap: 0.375rem;
    top: 1rem;
    right: ${props => props.hasStatus ? '8.5rem' : '1rem'};

    &::before {
      font-size: 1rem;
    }
  }
`;

const AdStatus = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  gap: 0.25rem;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: currentColor;
  }

  @media (min-width: 768px) {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    gap: 0.375rem;
    top: 1rem;
    right: 1rem;

    &::before {
      width: 6px;
      height: 6px;
    }
  }
  
  ${props => {
    const statusStyles = {
      abierta: {
        color: '#4CAF50',
        background: 'rgba(76, 175, 80, 0.15)'
      },
      'en proceso': {
        color: '#FF9800',
        background: 'rgba(255, 152, 0, 0.15)'
      },
      completada: {
        color: '#2196F3',
        background: 'rgba(33, 150, 243, 0.15)'
      },
      cancelada: {
        color: '#F44336',
        background: 'rgba(244, 67, 54, 0.15)'
      }
    };
    return `
      color: ${statusStyles[props.status?.toLowerCase()]?.color || '#757575'};
      background: ${statusStyles[props.status?.toLowerCase()]?.background || 'rgba(117, 117, 117, 0.15)'};
    `;
  }}
`;

const AdCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(11, 51, 87, 0.08);
  padding: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border: 1px solid transparent;
  position: relative;

  @media (min-width: 768px) {
    padding: 1.5rem;
    gap: 1rem;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(11, 51, 87, 0.12);
    border-color: rgba(11, 51, 87, 0.1);
  }
`;

const AdTitle = styled.h3`
  font-size: 0.9rem;
  color: #0b3357;
  margin: 0;
  padding-top: 2rem;
  line-height: 1.4;
  word-break: break-word;

  @media (min-width: 768px) {
    font-size: 1.1rem;
    padding-top: 2.5rem;
  }
`;

const AdInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  font-size: 0.8rem;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    font-size: 0.9rem;
    gap: 1rem;
  }
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  strong {
    color: #0b3357;
    font-weight: 600;
    font-size: 0.75rem;
    
    @media (min-width: 768px) {
      font-size: 0.85rem;
    }
  }
`;

const StatsSection = styled(FilterSection)``;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const StatItem = styled.div`
  text-align: center;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: #f0f4f8;
  }

  strong {
    display: block;
    font-size: 1.5rem;
    color: #0b3357;
    margin-bottom: 0.25rem;
  }

  span {
    font-size: 0.9rem;
    color: #64748b;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(11, 51, 87, 0.08);

  h3 {
    color: #0b3357;
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    color: #64748b;
    margin-bottom: 1.5rem;
  }

  ${CreateButton} {
    margin: 0 auto;
    background-color: #0b3357;
    border-color: #0b3357;
    
    &:hover {
      background-color: transparent;
      color: #0b3357;
    }
  }
`;

const LoadingState = styled(EmptyState)`
  h3 {
    color: #0b3357;
  }
`;

const ErrorState = styled(EmptyState)`
  h3 {
    color: #dc2626;
  }
  
  p {
    color: #ef4444;
    margin-bottom: 1.5rem;
  }
`;

function MyAds() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ads, status, error } = useSelector((state) => state.userAds);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("mas-recientes");
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(true);

  useEffect(() => {
    dispatch(fetchUserAds());
  }, [dispatch]);

  const handleCreateClick = () => {
    navigate('/ad-request');
  };

  const handleAdClick = (id) => {
    navigate(`/anuncio/${id}`);
  };

  const renderContent = () => {
    if (status === 'loading') {
      return (
        <LoadingState>
          <h3>Cargando tus anuncios...</h3>
        </LoadingState>
      );
    }

    if (status === 'failed') {
      return (
        <ErrorState>
          <h3>Error al cargar los anuncios</h3>
          <p>{error}</p>
          <CreateButton onClick={() => dispatch(fetchUserAds())}>
            Reintentar
          </CreateButton>
        </ErrorState>
      );
    }

    if (!ads || ads.length === 0) {
      return (
        <EmptyState>
          <h3>No tienes anuncios publicados</h3>
          <p>Comienza creando tu primer anuncio publicitario</p>
          <CreateButton onClick={handleCreateClick}>
            Crear Anuncio
          </CreateButton>
        </EmptyState>
      );
    }

    // Filter and sort ads
    let filteredAds = [...ads];
    
    // Apply status filter
    if (selectedStatus) {
      filteredAds = filteredAds.filter(ad => 
        ad.estado?.nombre?.toLowerCase() === selectedStatus
      );
    }

    // Apply sorting
    filteredAds.sort((a, b) => {
      switch (sortOrder) {
        case 'mas-recientes':
          return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
        case 'menos-recientes':
          return new Date(a.fechaCreacion) - new Date(b.fechaCreacion);
        default:
          return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
      }
    });

    const isToday = (date) => {
      const today = new Date();
      const creationDate = new Date(date);
      return creationDate.toDateString() === today.toDateString();
    };

    return filteredAds.map((ad) => {
      const isNew = isToday(ad.fechaCreacion);
      const hasStatus = ad.estado?.nombre;
      
      return (
        <AdCard key={ad.id} onClick={() => handleAdClick(ad.id)}>
          {isNew && <NewBadge hasStatus={hasStatus}>Nueva</NewBadge>}
          <AdStatus status={ad.estado?.nombre}>{ad.estado?.nombre || 'Pendiente'}</AdStatus>
          <AdTitle>{ad.descripcionCampania}</AdTitle>
          <AdInfo>
            <InfoItem>
              <strong>Descripción:</strong>
              {ad.descripcionProducto || 'No especificada'}
            </InfoItem>
            <InfoItem>
              <strong>Presupuesto:</strong>
              {formatMonto(ad.presupuesto, ad.moneda?.simbolo)}
            </InfoItem>
            <InfoItem>
              <strong>Departamento:</strong>
              {ad.departamento?.nombre}
            </InfoItem>
            <InfoItem>
              <strong>Fecha de cierre:</strong>
              {formatDate(ad.fechaCierre)}
            </InfoItem>
            <InfoItem>
              <strong>Publicación:</strong>
              {formatDate(ad.fechaPublicacion)}
            </InfoItem>
            <InfoItem>
              <strong>Finalización:</strong>
              {formatDate(ad.fechaFinPublicacion)}
            </InfoItem>
          </AdInfo>
        </AdCard>
      );
    });
  };

  const activeAds = ads?.filter(ad => ad.estado?.nombre === 'Abierta')?.length || 0;
  const totalAds = ads?.length || 0;

  return (
    <PageContainer>
      <Header>
        <HeaderContent>
          <Title>Mis Anuncios</Title>
          <CreateButton onClick={handleCreateClick}>
            Crear Anuncio
          </CreateButton>
        </HeaderContent>
      </Header>

      <ContentContainer>
        <SideContent>
          <FilterSection isCollapsed={isFilterCollapsed}>
            <h2 onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}>
              Filtros
            </h2>
            <FilterContent isCollapsed={isFilterCollapsed}>
              <FilterGroup>
                <label>Estado</label>
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  <option value="">Todos</option>
                  <option value="abierta">Abiertos</option>
                  <option value="en proceso">En proceso</option>
                  <option value="completada">Completados</option>
                  <option value="cancelada">Cancelados</option>
                </select>
              </FilterGroup>
              <FilterGroup>
                <label>Ordenar por</label>
                <select
                  value={sortOrder}
                  onChange={(e) => setSortOrder(e.target.value)}
                >
                  <option value="mas-recientes">Más recientes</option>
                  <option value="menos-recientes">Menos recientes</option>
                </select>
              </FilterGroup>
            </FilterContent>
          </FilterSection>
          <ActivitySummary />
        </SideContent>

        <MainContent>
          {renderContent()}
        </MainContent>
      </ContentContainer>
    </PageContainer>
  );
}

export default MyAds;
